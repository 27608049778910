import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ArticleEditor from 'rev.sdk.js/Components/ArticleEditor';
import SEO from '../../components/seo';
import {parse} from 'query-string';
import {message} from 'antd';

const collectionName = 'Article_Default';

function errorHandler(err) {
  console.warn(err);
  if (err.response && err.response.error === 'permission_denied') {
    message.error('憑證已過期，請重新進入', 0);
  }
}

export default function StandaloneEditorPage(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [article, setArticle] = React.useState(null);
  const params = parse(props.location.search);

  React.useEffect(() => {
    async function login(jwtToken) {
      try {
        await actions.loginViaJwt(jwtToken);
      } catch (ex) {
        const errMsg = `LoginViaJwt Fail: ${JSON.stringify(ex, null, 2)}`;
        console.warn(errMsg);
      }
    }

    async function fetchArticle(id) {
      if (!id) {
        // set an empty article, so ArticleDetail can edit it
        setArticle({});
        return;
      }

      try {
        setArticle(await JStorage.fetchOneDocument(collectionName, {id}));
      } catch (ex) {
        errorHandler(ex);
      }
    }

    if (!params.token) {
      return message.error('找不到token，請以正確的方式開啟編輯器', 0);
    }

    if (!user) {
      login(params.token);
    } else {
      fetchArticle(params.id);
    }
  }, [user, actions, params.token, params.id]);

  if (!article) {
    return (
      <Wrapper>
        <Loader width="200" />
        <Loader />
        <Loader style={{height: 80, width: 180}} delay={0.9} />
        <Loader width={80} delay={0.4} />
        <Loader style={{height: 300, width: '100%'}} delay={0.4} />
        <Loader delay={0.2} />
        <Loader width={220} delay={0.8} />
        <Loader width={300} delay={0.7} />
        <Loader width={280} delay={0.9} />
      </Wrapper>
    );
  }

  const isCreate = !article.id;

  return (
    <>
      <SEO title={article.title || 'Revteltech Dashbhard'} />

      <Wrapper>
        {/* <h2>{isCreate ? 'Create New Article' : 'Update Article'}</h2> */}
        <ArticleEditor
          collection={{name: collectionName}}
          document={isCreate ? null : article}
          onUpdate={(createdDoc) => {
            if (createdDoc) {
              alert('建立成功!');
              navigate(
                `/article/editor?id=${createdDoc.id}&token=${params.token}`,
              );
            } else {
              alert('編輯成功!');
            }
          }}
          onClose={() => 0}
          onPreview={(document) => {
            window.open(
              `/article?id=${document.id}&client=${user.client}`,
              '_blank',
            );
          }}
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0px auto;
  padding: 20px 20px 0 20px;
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Loader = styled.div`
  background-color: #f5f5f5;
  height: 20px;
  width: ${(props) => props.width || '50'}px;
  margin-bottom: 20px;

  @keyframes spark {
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  animation: spark 1s linear infinite;
  animation-delay: ${(props) => props.delay || 0}s;
`;
